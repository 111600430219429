<!-- Use este arquivo como base para novos módulos de CRUD, -->
<!-- apenas copiando ele e colando na pasta respectiva -->

<template>
  <crud-base
    :url="url"
    :columns="columns"
    :onDelete="() => {}"
    :onUpdate="() => {}"
    :onStore="() => {}"
    :enableSearch="true"
    :formInputs="formInputs"
    :checkAlerts="true"
    primaryKey="id"
  />
</template>

<script>
import CrudBase from "../../../components/crud/CrudBase";
import {
  schemaBoolean,
  schemaSqlEditor,
  schemaTextArea,
  schemaTextField
} from "@/helpers/form";
export default {
  name: "crud-template",
  components: {
    CrudBase,
  },
  data() {
    return {
      url: "/admin/condicoes/cobranca-plano",
      columns: [
        { label: "ID", field: "id", sortable: false },
        { label: "ID da Cobrança", field: "cobranca_id", sortable: false },
        { label: "ID do Plano de Pagamento", field: "planopagamento_id", sortable: false }
      ],
    };
  },
};
</script>
