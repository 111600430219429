<template>
  <crud-base
    :url="url"
    :columns="columns"
    :onDelete="() => {}"
    :onUpdate="() => {}"
    :onStore="() => {}"
    :enableSearch="true"
    :formInputs="formInputs"
    :check-alerts="true"
    primaryKey="id"
  />
</template>

<script>
import CrudBase from "../../../components/crud/CrudBase";
import {
  schemaBooleanSelect,
  schemaMoney,
  schemaNumber,
  schemaPercent,
  schemaTextArea,
  schemaTextField
} from "@/helpers/form";
import {yesNoRepresentation} from "@/helpers/transformers";

export default {
  name: "operacoes",
  components: {
    CrudBase,
  },
  data() {
    return {
      url: "/admin/condicoes/operacoes",
      columns: [
        {
          label: "Id",
          field: "id",
          sortable: false
        },
        {
          label: "Descrição",
          field: "descricao",
          sortable: false
        },
        {
          label: "Visível B2B",
          field: "acessob2b",
          sortable: false,
          representedAs: (row) => yesNoRepresentation(row.acessob2b)
        },
        {
          label: "Visível CALL",
          field: "acessocallcenter",
          sortable: false,
          representedAs: (row) => yesNoRepresentation(row.acessocallcenter)
        },
        {
          label: "Visíel FV",
          field: "acessofv",
          sortable: false,
          representedAs: (row) => yesNoRepresentation(row.acessofv)
        },
        {
          label: "Ativo",
          field: "ativo",
          sortable: false,
          representedAs: (row) => yesNoRepresentation(row.ativo)
        },
        {
          label: "Ordem",
          field: "acessofv",
          sortable: false,
        }
      ],
      formInputs: {
        id: schemaTextField('Cód.', true, 2),
        ativo: schemaBooleanSelect('Ativo', true, 2, 1, 1),
        acessob2b: schemaBooleanSelect('Visível B2B?', true, 2, 1, 1),
        acessocallcenter: schemaBooleanSelect('Visível Call?', true, 2, 1, 1),
        acessofv: schemaBooleanSelect('Visível FV?', true, 2, 1, 1),
        bonificacao: schemaBooleanSelect('É bonificação?', true, 2, 1, 0),

        descricao: schemaTextField('Descrição', true, 6),
        recalcula: schemaBooleanSelect('Recalcula itens após mudar?', true, 4, 1, 1),
        tabelapreco: schemaTextField('Cód. Tab. Preço', false, 2),

        valida_multiplo: schemaBooleanSelect('Valida múltiplo?', true, 2, 1, 1),
        valida_estoque: schemaBooleanSelect('Valida estoque?', true, 2, 1, 1),
        valida_plano: schemaBooleanSelect('Valida planos?', true, 2, 1, 0),
      },
    };
  },
};
</script>
