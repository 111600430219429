<template>
  <crud-base
    :url="url"
    :columns="columns"
    :onDelete="() => {}"
    :onUpdate="() => {}"
    :onStore="() => {}"
    :enableSearch="true"
    :formInputs="formInputs"
    :check-alerts="true"
    :transform="transform"
    primaryKey="id"
  />
</template>

<script>
import CrudBase from "../../../components/crud/CrudBase";
import {
  schemaBooleanSelect,
  schemaMoney,
  schemaNumber,
  schemaPercent,
  schemaSelect,
  schemaTextField
} from "@/helpers/form";
import {yesNoRepresentation} from "@/helpers/transformers";

export default {
  name: "planos",
  components: {
    CrudBase,
  },
  data() {
    return {
      url: "/admin/condicoes/planos",
      columns: [
        {
          label: "Id",
          field: "id",
          sortable: false
        },
        {
          label: "Descrição",
          field: "descricao",
          sortable: false
        },
        {
          label: "Visível B2B",
          field: "acessob2b",
          sortable: false,
          representedAs: (row) => yesNoRepresentation(row.acessob2b)
        },
        {
          label: "Visível CALL",
          field: "acessocallcenter",
          sortable: false,
          representedAs: (row) => yesNoRepresentation(row.acessocallcenter)
        },
        {
          label: "Visíel FV",
          field: "acessofv",
          sortable: false,
          representedAs: (row) => yesNoRepresentation(row.acessofv)
        },
        {
          label: "Ativo",
          field: "ativo",
          sortable: false,
          representedAs: (row) => yesNoRepresentation(row.ativo)
        },
      ],
      formInputs: {
        id: schemaTextField('Cód.', true, 2),

        ativo: schemaBooleanSelect('Ativo', true, 2, 1),
        acessob2b: schemaBooleanSelect('Visível B2B?', true, 2, 1),
        acessocallcenter: schemaBooleanSelect('Visível Call?', true, 2, 1),
        acessofv: schemaBooleanSelect('Visível FV?', true, 2, 1),
        exibecheckout: schemaBooleanSelect('Exibe Checkout?', true, 2, 1),

        descricao: schemaTextField('Descrição', true, 6),
        descricaob2b: schemaTextField('Descrição (b2b)', false, 6),

        valorminimo: schemaMoney('Valor mínimo', true, 3, 0),
        qtdminimaitens: schemaNumber('Qtd. Min. itens?', true, 3, 0),
        margemminima: schemaPercent('Margem Min. (%)', false, 3, 0),
        percfinanceiro: schemaPercent('Desc/Acrés Fin. (%)', false, 3),

        prazomedio: schemaNumber('Prazo médio', true, 3, 0),
        descricaoprazomedio: schemaTextField('Descrição prazo', false, 3),
        numeroparcelas: schemaNumber('Número parcelas', true, 3, 1),
        dias_parcelas: schemaTextField('Descrição parcelas', false, 3),

        permite_flex: schemaBooleanSelect('Aceita flex?', true, 3, 1),
        preco: schemaSelect('Num. Col. preço', true, 3, {
          '1' : "Preço 1",
          '2' : "Preço 2",
          '3' : "Preço 3",
          '4' : "Preço 4",
          '5' : "Preço 5",
          '6' : "Preço 6",
          '7' : "Preço 7"
        }, '1'),
        especial: schemaBooleanSelect('É especial?', true, 3, 1, 0),
        tipo_restricao: schemaSelect('Restrição', true, 3, {
          "NR" : "Não restrito",
          "CL" : "Cliente",
          'FO' : "Fornecedor",
          'PR' : "Produto",
          'GR' : "Grupo de cliente",
          'GA' : "Grupo de autorização de cliente",
          'RA' : "Ramo de atividade",
          'D1' : "Dimensão 1",
          'D2' : "Dimensão 2",
          'D3' : "Dimensão 3",
          'D4' : "Dimensão 4",
          'D5' : "Dimensão 5",
          'D6' : "Dimensão 6",
          'D7' : "Dimensão 7",
          'D8' : "Dimensão 8",
          'D9' : "Dimensão 9",
          'D10' : "Dimensão 10",
          'VLD1' : "Valor mínimo por dimensão 1",
          'VLD2' : "Valor mínimo por dimensão 2",
          'VLD3' : "Valor mínimo por dimensão 3",
          'VLD4' : "Valor mínimo por dimensão 4",
          'VLD5' : "Valor mínimo por dimensão 5",
          'VLD6' : "Valor mínimo por dimensão 6",
          'VLD7' : "Valor mínimo por dimensão 7",
          'VLD8' : "Valor mínimo por dimensão 8",
          'VLD9' : "Valor mínimo por dimensão 9",
          'VLD10' : "Valor mínimo por dimensão 10",
        }, 'NR'),

        natureza_id: schemaTextField('Cód. Natureza', false, 3),
        centrocusto_id: schemaTextField('Centro Custo', false, 3),
      },
    };
  },
  methods: {
    transform: (row) => {
      row.preco = `${row.preco}`;
      return row;
    }
  }
};
</script>
